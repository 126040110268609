
import { Component, Vue, Watch, } from 'vue-property-decorator'
import { mapGetters, } from 'vuex'
import { format, } from 'date-fns'
import OverlayBackground from "@/components/OverlayBackground/Index.vue"
import { GapInterface, } from '@/store/types/Analysis/GapAnalitic'

import DashboardStatusGraph from '@/components/DashboardStatusGraph/Index.vue'

import { ApplicationsInterface, } from '@/utils/Applications'
import ConfirmDialog from '@/components/ConfirmDialog/Index.vue'

@Component({
  components: {
    DashboardStatusGraph,
    ConfirmDialog,OverlayBackground
  },
  computed: {
    ...mapGetters('GapModule', ['getVersionCategories',]),
    ...mapGetters('GapModule', ['getFrameworkSelectedHistory',]),
    ...mapGetters('MetricsModule', ['getSelectedCats', 'getTotalGraph',]),
    ...mapGetters('PolimorficModule', ['getFrameworkSelected',]),
  },
})
export default class GapClientComponent extends Vue {
  private Business = this.$store.state.BusinessModule.BusinessState.businessClient
  private versionApp = this.$store.state.GapModule.GapState.version_categories
  private role = this.$store.state.AuthModule.AuthState.role
  private versionDate = this.$store.state.GapModule.GapState.dateVersion
  private progressData = null
  private showMenu = false
  private confirm = false
  private renderComponent = true
  private applications: ApplicationsInterface[] = []
  private dateToVersions: string[] = []
  private date = format(new Date(), 'yyyy-MM-dd')
  private refreshDonut = false
  private loading = false;
  private ADMIN_ROLES = process.env.VUE_APP_PRIVILEGED_ROLES?.split(",") || [];
  private mounted () {
    this.getData()
    this.sortSearchResults()
  }

  @Watch('$store.state.PolimorficModule.PolimorficState.framework_selected')
  changeFramework (): void {
    this.getData()
  }

  private sortSearchResults() {
    this.$store.state.SearchModule.SearchState.searchResults.sort((control: any, nextControl: any) => {
      const [, controlId] = control.control_id.split('.');
      const [, nextControlId] = nextControl.control_id.split('.');
      const controlIdParsed = parseInt(controlId, 10);
      const nextControlIdParsed = parseInt(nextControlId, 10);
      
      return controlIdParsed - nextControlIdParsed;
    });
  }  

  private getData () {
    this.renderComponent = false
    this.refreshDonut = false
    this.$store.dispatch('MetricsModule/getUncomplies')
    this.$store.dispatch('MetricsModule/getCompliesCat').then((data) => {
      this.$store.commit('SearchModule/SET_SEARCH_RESULTS', data.data.category)
      this.$store.commit('GapModule/SET_GAP_CATEGORIES', data.data.category)
      if (data.data.publish_gap_analysis) {
        this.$store.commit('GapModule/SET_PUBLIC_GAP', data.data.publish_gap_analysis)
      }
      if (data.data.publish_advance) {
        this.$store.commit('GapModule/SET_ADVANCE_GAP', data.data.publish_advance)
      }
      this.renderComponent = true
      this.refreshDonut = true
    })
    this.$store.dispatch('MetricsModule/getCompliesTotal')
  }

  private goback () {
    this.$router.go(-1)
  }

  private goToControl (element: GapInterface) {
    this.$store.commit('GapModule/SET_CONTROL_SELECTED', element)

    // If para saber si entra por implementador o por Cliente
    if (!this.ADMIN_ROLES.some((adminRole: string) => this.role.includes(adminRole))){
      // If Para saber si entra en modo Historia o En Modo estado actual

      this.versionApp === 0
        ? this.$router.push(`/startup/project/category/${element.id}`)
        : this.$router.push({
          name: 'historyStartupControlsList',
          params: { id_category: element.id ? element.id.toString() : 'null', },
        })
      this.$mixpanel.track('Categoria - Estado completo Cliente', {'Categoria': element.name,})
    } else {
      // Entra por implementador

      this.$router.push({
        name: 'historyControlsList',
        params: { id_category: element.id ? element.id.toString() : 'null', },
      })
    }
  }

  // private getCategories () {
  //   this.renderComponent = false
  //   this.loading = true
  //   this.$store
  //     .dispatch('GapModule/getGapCategories', this.Business.id)
  //     .then((data) => {
  //       this.$store.commit('SearchModule/SET_SEARCH_RESULTS', data.data.gap_analysis_categories)
  //       this.progressData = data.data.totals
  //       // this.refreshDonut = !this.refreshDonut
  //       this.$nextTick().then(() => {
  //         // Add the component back in
  //         this.renderComponent = true
  //       })
  //       this.loading = false
  //     })
  //     .catch(() => {
  //       this.loading = false
  //     })
  // }

  private changeGapVersion (element: number) {
    this.$store.commit('GapModule/SET_VERSION_CATEGORIES', element)
    this.getData()
  }

  private exportGapAnalysis () {
    this.loading = true
    this.$store
      .dispatch('GapModule/exportGap', this.Business.id)
      .then(() => {
        this.loading = false
      })
      .catch(() => {
        this.loading = false
      })
  }

  private exportToJira () {
    if (this.applications.length === 0) {
      this.$store.dispatch('NotificactionsModule/OpenNotification', {
        message: 'Error, Debe tener al menos una cuenta conectada a JIRA',
        color_notification: 'danger',
      })
      return
    }
    this.loading = true
    this.$store
      .dispatch('GapModule/exportJira', this.Business.id)
      .then(() => {
        this.$store.dispatch('NotificactionsModule/OpenNotification', {
          message: 'Éxito, Sus tareas pendientes han sido agregadas a Jira',
        })
        this.showMenu = false
        this.loading = false
      })
      .catch(() => {
        this.loading = false
      })
  }
}
